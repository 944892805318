import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import type { HTMLAttributes, JSX, MutableRefObject } from "react";
import { Checkbox, TablePagination, IconButton } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { TABLE_COMPANY } from "../AccessControl/AccessControl";
import ToolsBarTable from "./ToolsBarTable";
import MainLoader from "../Feedback/MainLoader";
import { Name } from "../../Dashboard/Evaluation/Result/ResultOther/TypeTable/TotalList"
import renderEditableTextField from "./TextFieldEditable";
import type { Pagination, StateProps } from "../../../types";
import NoData from "./NoData";
import ScrollContext from "../context/scrollContext";

export interface TableColumnInterface {
    field: string,
    headerName: string,
    minWidth: number,
    flex: number,
    type: string,
    isEditable?: boolean,
    title?: string,
    className?: HTMLAttributes<HTMLTableCellElement>["className"];
    style?: HTMLAttributes<HTMLTableCellElement>["style"];
    exportValue?(data: any): string,
    valueGetter?(params: any): string,
    renderCell?(params: any): string,
}

function getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((o, p) => (o && o[p] ? o[p] : null), obj);
}

function filterEmptyColumns(columns: any[], rows: any[]) {
    return columns.filter((column) => {
        for (let row of rows) {
            const value = getNestedValue(row, column.field);

            if (value !== null && value !== undefined && value !== '') {
                return true;
            }
        }

        return false;
    });
}

type RenderRowCellProps = {
    row: any;
    index: number;
    col: TableColumnInterface;
};

type RenderRowProps = {
    index: number;
    row: any;
    select: number[];
    columnsRendered: any[];
    isResultOther?: boolean;
    handleChangeChecked: (checked: boolean, id: number) => void;
    tableCellStyleUpdateOnScroll?: (tableCell: any, rowId: number, tableRow?: any) => void;
    scrollColumnName?: string;
};

function RenderRowCell({ row, index, col }: RenderRowCellProps): JSX.Element {
    const [inputValue, setInputValue] = useState("");
    const [currentlyEditing, setCurrentlyEditing] = useState<number | null>(null);

    const getDataColFromRow = (column: TableColumnInterface, row: any): string => {
        if (column.renderCell !== undefined && column.renderCell !== null)
            return column.renderCell({row: row});
        else if (!!row[column.field])
            return row[column.field] as string;
        return "";
    };

    const handleInputChange = (_rowIndex: number, key: string, value: string) => {
        row[key] = value;
        setInputValue(value);
    };

    const toggleEditable = (rowIndex: number, shouldEdit: boolean) => {
        if (currentlyEditing && currentlyEditing !== rowIndex)
            toggleEditable(currentlyEditing, false);
        setCurrentlyEditing(shouldEdit ? rowIndex : null);
    };

    useEffect(() => setInputValue(row[col.field]), [row, col.field]);

    if (col.isEditable)
        return renderEditableTextField(
            col.field,
            index,
            inputValue,
            currentlyEditing,
            handleInputChange,
            toggleEditable,
            col.type
        );
    return <>{getDataColFromRow(col, row)}</>;
}

function RenderRow({
    index,
    row,
    select,
    columnsRendered,
    isResultOther,
    handleChangeChecked,
    tableCellStyleUpdateOnScroll,
    scrollColumnName
}: RenderRowProps): JSX.Element {
    const [hover, setHover] = useState<boolean>(false);
    const rowRef: MutableRefObject<HTMLTableRowElement | null> = useRef<HTMLTableRowElement | null>(null);

    function is_number_align(col: TableColumnInterface){
        return (col.renderCell !== undefined && col.renderCell !== null && typeof(col.renderCell({row: row})) === 'string' ?
            (!isNaN(Number(col.renderCell({row: row}).replace(/\s+/g, ''))) ? "end" : "start") : "start");
    }

    function is_number_pos(col: TableColumnInterface){
        return (col.renderCell !== undefined && col.renderCell !== null && typeof(col.renderCell({row: row})) === 'string' ?
            (!isNaN(Number(col.renderCell({row: row}).replace(/\s+/g, ''))) ? col.minWidth + (col.headerName.length * 2) : 0) : 0);
    }

    const hasMatchingName = Object.values(row).some(value => {
        if (typeof value === 'string') {
            return Name.includes(value);
        }

        return false;
    });

    const toggleScrollStyle = useCallback((cell: any, rowid: number) => {
        if (tableCellStyleUpdateOnScroll) tableCellStyleUpdateOnScroll(cell, rowid, rowRef);
    }, [rowRef, tableCellStyleUpdateOnScroll]);

    return (
        <tr
            className={hasMatchingName && isResultOther ? `p-2 border border-grey-400 text-center text-base font-semibold antialiased bg-blue-50 bg-opacity-90 whitespace-no-wrap` : ""}
            key={`${index}-${row.id}-table-body-rows`}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => setHover(false)}
            onClick={() => handleChangeChecked(!select.includes(row.id), row.id)}
            ref={rowRef}
            style={{ backgroundColor: hover || select.includes(row.id) ? 'rgba(74, 91, 203, 0.26)' : hasMatchingName && isResultOther ? '' : 'white' }}
        >
            <td style={{
                minWidth: 10,
                borderBottom: '1px solid whitesmoke',
            }}>
                <Checkbox
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeChecked(event.target.checked, row.id)
                    }
                    checked={select.includes(row.id)}
                    size={'small'}
                />
            </td>
            {columnsRendered.map((col: TableColumnInterface, colNb: number) => {
                col.style = {
                    zIndex: 1000,
                    minWidth: col.minWidth + (col.headerName.length * 2),
                    fontWeight: hasMatchingName && isResultOther ? 800 : 400,
                    fontSize: 14,
                    whiteSpace: 'pre',
                    textAlign: is_number_align(col),
                    paddingRight: is_number_pos(col),
                    borderBottom: '1px solid whitesmoke',
                };

                // Dans le cas ou aucune colune spécifique n'a été précisé dans les parametres de la fonction
                if (!scrollColumnName) {
                    // Par défaut activer le scroll sur les champ "nom" du tableau
                    if (col.field === 'catalogue.nom' || col.field === 'nom') toggleScrollStyle(col, row.id);
                } else {
                    // Sinon chercher la colonne contenant le parametre la valeur de "scrollColumnName"
                    if (col.field === scrollColumnName) toggleScrollStyle(col, row.id);
                }

                return (
                    <td
                        key={`${col.headerName}-${colNb}-columns-table-body-pagination`}
                        className={col.className}
                        style={col.style}
                    >
                        <RenderRowCell row={row} index={index} col={col} />
                    </td>
                );
            })}
        </tr>
    );
}

interface RenderHeaderProps {
    rows: any[],
    columnsRendered: any[],
    setSelect: React.Dispatch<React.SetStateAction<number[]>>,
    select: number[],
    columns: TableColumnInterface[],
    sort: "asc" | "desc",
    onSelect?(ids: number[]): any,
    handleSort(column: TableColumnInterface): void,
    thRef: MutableRefObject<HTMLTableSectionElement | null>;
}

function RenderHeaderTable({
    rows,
    setSelect,
    onSelect = (_: number[]) => {},
    columnsRendered,
    select,
    columns,
    sort,
    handleSort,
    thRef
}: RenderHeaderProps): JSX.Element {
    const handleAllChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const checked = rows.map((elem: any) => elem.id);
            setSelect(checked);
            onSelect(checked);
        } else {
            setSelect([]);
            onSelect([]);
        }
    }, [onSelect, rows, setSelect]);

    return (
        <thead ref={thRef} className={'sticky top-0 z-[100]'}>
            <tr>
                <th className={'text-white py-[8px] min-w-[10px] border-[#4A5BCB] bg-[#4A5BCB]'}>
                    <Checkbox
                        onChange={handleAllChecked}
                        checked={select.length === rows.length}
                        size={'small'}
                        style={{color: 'white'}}
                    />
                </th>

                {columnsRendered.map((elem: TableColumnInterface, index: number) => {
                    const th = (elem: TableColumnInterface): JSX.Element => {
                        if (elem.title) {
                            const breaklineContent: string = elem.headerName.replace(elem.title, ``);

                            return (
                                <div className={'flex flex-row content-center gap-3'}>
                                    {elem.title} <br/>
                                    {breaklineContent.includes(' | ')
                                        ? breaklineContent.replace(' | ', '')
                                        : breaklineContent
                                    }

                                    <IconButton
                                        size={'small'}
                                        onClick={() => {
                                            handleSort(elem);
                                        }}
                                    >
                                        {sort === 'asc'
                                            ? <ArrowDropDown style={{color: 'white'}}/>
                                            : <ArrowDropUp style={{color: 'white'}}/>
                                        }
                                    </IconButton>
                                </div>
                            );
                        }

                        return (
                            <>
                                {elem.headerName}

                                <IconButton
                                    size={'small'}
                                    onClick={() => {
                                        handleSort(elem);
                                    }}
                                >
                                    {sort === 'asc'
                                        ? <ArrowDropDown style={{color: 'white'}}/>
                                        : <ArrowDropUp style={{color: 'white'}}/>
                                    }
                                </IconButton>
                            </>
                        );
                    }

                    return (
                        <th
                            key={`${elem.headerName}-${index}-columns-table-pagination`}
                            className={'text-white font-medium text-[14px] border-[#4A5BCB] bg-[#4A5BCB] text-left py-[10px] whitespace-nowrap'}
                        >
                            {th(elem)}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}

interface TablePaginationProps {
    scrollColumnName?: string;
    columns: TableColumnInterface[],
    data: any[],
    rows: any[],
    isLoading: boolean,
    resetSearch?: boolean;
    origin: string,
    emptyColumns?: boolean,
    isUpdateDisable?: boolean,
    isDeleteDisable?: boolean,
    isRestoreDisable?: boolean,
    isLockDisable?: boolean,
    rolesSection?: string,
    exportUrl?: string | null,
    exportFilter?: any | null,
    pagination?: Pagination,
    askLoad?: boolean,
    isResultOther?: boolean,
    clearLoaded?: boolean,
    sortWithoutPagination?: boolean,
    handleClearLoaded?(): any,
    handleOpenCreate?(): any,
    handleOpenUpdate?(): any,
    handleOpenDelete?(): any,
    handleOpenReference?(): any,
    handleOpenRestore?(): any,
    handleFilterSearch?(data: any[]): any,
    onSelect?(ids: number[]): any,
    handleOpenLock?(): any,
    handleDuplicate?(name: string): any,
    handlePageChange?: (pageNumber: number, filter: string, resetData?: boolean, sort?: string) => void;
    handleSortChange?: (sort: string, collumnName: string) => void;
}

export default function TablePaginationPro({
    scrollColumnName,
    columns,
    data = [],
    isLoading = false,
    resetSearch = false,
    rows = [],
    origin = "document",
    handleOpenCreate = undefined,
    handleOpenUpdate = undefined,
    isUpdateDisable = false,
    handleOpenDelete = undefined,
    handleOpenReference = undefined,
    isDeleteDisable = false,
    handleOpenRestore = undefined,
    isRestoreDisable = false,
    handleFilterSearch = undefined,
    onSelect = (_: number[]): void => {},
    exportUrl = null,
    handleOpenLock = undefined,
    isLockDisable = false,
    handleDuplicate = undefined,
    rolesSection = TABLE_COMPANY,
    pagination = undefined,
    handlePageChange = undefined,
    emptyColumns = false,
    exportFilter = null,
    clearLoaded,
    handleClearLoaded,
    askLoad = false,
    isResultOther,
    sortWithoutPagination = true,
    handleSortChange = (sort: string, collumnName: string): void => {},
}: TablePaginationProps): JSX.Element {
    const [select, setSelect] = useState<number[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [page, setPage] = useState<number>(pagination && pagination!.page_actuel ? pagination!.page_actuel - 1 : 0);
    const [loadedPage, setLoadedPage] = useState<number[]>([0]);
    const [renderedRows, setRenderedRows] = useState<any[]>([]);
    const [columnsRendered, setColumnsRendered] = useState<any[]>(columns);
    const [sort, setSort] = useState<"asc" | "desc">("asc");
    const nonEmptyColumns: any[] = filterEmptyColumns(columns, rows);
    const scrollContext = useContext<number>(ScrollContext);
    const [tableScrollX, setTableScrollX]: StateProps<number> = useState<number>(0);
    const thRef: MutableRefObject<HTMLTableSectionElement | null> = useRef<HTMLTableSectionElement | null>(null);
    const tbodyRef: MutableRefObject<HTMLTableSectionElement | null> = useRef<HTMLTableSectionElement | null>(null);
    const divRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const [originalStyles, setOriginalStyles] = useState<Record<number, {
        renderElementClass?: string,
        renderElementStyle?: React.CSSProperties,
    }>>({});

    useEffect(() => {
        setSelect([]);
        onSelect([]);

        if (clearLoaded === true) {
            setLoadedPage([0]);

            if (handleClearLoaded) handleClearLoaded();
        }
    }, [isLoading, clearLoaded]);

    useEffect(() => setPage(pagination && pagination!.page_actuel ? pagination!.page_actuel - 1 : 0), [pagination]);

    useEffect(() => setFilter(""), [resetSearch]);

    const updateRenderedRows = useCallback(() => {
        // if (sortWithoutPagination) {
        //     let startIndex, endIndex;
        //
        //     if (pagination) {
        //         startIndex = page * pagination.par_page;
        //         endIndex = startIndex + pagination.par_page;
        //     } else {
        //         startIndex = page * 50;
        //         endIndex = startIndex + 50;
        //     }
        //
        //     const newRenderedRows = rows.slice(startIndex, endIndex);
        //     setRenderedRows(newRenderedRows);
        // }

        let startIndex, endIndex;

        if (pagination) {
            startIndex = page * pagination.par_page;
            endIndex = startIndex + pagination.par_page;
        } else {
            startIndex = page * 50;
            endIndex = startIndex + 50;
        }

        const newRenderedRows = rows.slice(startIndex, endIndex);
        setRenderedRows(newRenderedRows);

    }, [page, pagination, rows]);

    useEffect(() => {
        updateRenderedRows();
    }, [rows, page]);

    useEffect(() => {
        if (emptyColumns)
            setColumnsRendered(columns);
        else
            setColumnsRendered(nonEmptyColumns);
    }, [columns, emptyColumns]);

    const handleChangeFilter = (str: string) => {
        setFilter(str);
        if (!!handlePageChange) {
            setPage(0);
            setLoadedPage([0]);
            handlePageChange(1, str, true);
        }
    };

    const handleChangeChecked = useCallback((selected: boolean, id: number) => {
        if (selected) {
            const updatedSelect = [...select, id];
            setSelect(updatedSelect);
            onSelect(updatedSelect);
        } else {
            const updatedSelect = select.filter((elem: number) => elem !== id);
            setSelect(updatedSelect);
            onSelect(updatedSelect);
        }
    }, [onSelect, select]);

    const handleSort = useCallback((column: TableColumnInterface) => {
        setSort((prevSort) => (prevSort === "asc" ? "desc" : "asc"));

        if (sortWithoutPagination) {
            setRenderedRows((prevRenderedRows) => {
                return data.sort((a: any, b: any) => {
                    const getValue = column.valueGetter !== undefined && column.valueGetter !== null
                        ? column.valueGetter
                        : (row: any) => row[column.field];

                    const aVal = getValue({row: a});
                    const bVal = getValue({row: b});

                    if (aVal < bVal) return sort === "asc" ? -1 : 1;
                    if (aVal > bVal) return sort === "asc" ? 1 : -1;
                    return 0;
                });
            });
        } else {
            handleSortChange(sort, column.field);
        }
    }, [sortWithoutPagination, data, sort, handleSortChange]);
    
    const backToInitialStyle = useCallback((tableCell: TableColumnInterface, rowId: number,): void => {
        const originalStyle = originalStyles[rowId];

        // Back to the original style of the cell
        if (originalStyle) {
            tableCell.className = '';
            tableCell.style = originalStyle.renderElementStyle;
        }
    }, [originalStyles]);

    const tableCellStyleUpdateOnScroll = useCallback((tableCell: TableColumnInterface, rowId: number, tableRow?: MutableRefObject<HTMLTableRowElement | null>): void => {
        if (!tableRow || !thRef || !divRef || !tbodyRef) return;
        if (!tableRow.current || !thRef.current || !divRef.current || !tbodyRef.current) return;

        const selecRowCheckboxWidth: number = 38; // 38px représente la largeur de la colonne contenant le checkbox de selection

        // Si la celulle n'est pas vide
        if (tableCell.renderCell || !!tableCell.exportValue) {
            // Si le scroll horizontal a dépassé la largeur de la colonne select row
            if (tableScrollX > selecRowCheckboxWidth) {
                if (
                    (tableRow.current.getBoundingClientRect().bottom <= (divRef.current.getBoundingClientRect().bottom - (scrollContext / 1.5))) &&
                    (tableRow.current.getBoundingClientRect().top >= thRef.current.getBoundingClientRect().top)
                ) {
                    if (!originalStyles[rowId]) {
                        const initialStyles = {
                            renderElementClass: "",
                            renderElementStyle: tableCell.style
                        };

                        // Sauvegarder le style original de la ligne [rowId] du tableau
                        setOriginalStyles(prev => ({ ...prev, [rowId]: initialStyles }));
                    }

                    // Modification du CSS de la celulle pour un rendu correct en fonction de si la tableau est scrollable
                    tableCell.className = 'fixed px-2 py-1 mt-0.5 -ml-10 bg-white border rounded-r-2xl drop-shadow-sm';
                    tableCell.style    = {
                        'marginTop': '0.2em',
                        'height'   : 'auto',
                        'top'      : `${tableRow.current.getBoundingClientRect().top}px`
                    };
                } else backToInitialStyle(tableCell, rowId);
            } else backToInitialStyle(tableCell, rowId);
        }
    }, [tableScrollX, backToInitialStyle, scrollContext, originalStyles]);

    const handleTableScroll = useCallback((e: React.UIEvent<HTMLElement>): void => {
        const el = e.target as HTMLElement;
        const { scrollLeft } = el;

        setTableScrollX(scrollLeft);
    }, [setTableScrollX]);

    useEffect(() => {
        setTableScrollX(0); //* reset style de la colonne code osmose
    }, [setTableScrollX]);

    return (
        <div className={`flex w-full h-full shadow rounded-lg min-h-[400px]`}>
            <ToolsBarTable
                handleDuplicate={handleDuplicate}
                rolesSection={rolesSection}
                origin={origin}
                handleOpenRestore={handleOpenRestore}
                exportUrl={exportUrl}
                isRestoreDisable={isRestoreDisable}
                handleFilterSearch={handleFilterSearch}
                disableSearch={askLoad}
                isUpdateDisable={isUpdateDisable}
                resetSearch={resetSearch}
                handleOpenUpdate={handleOpenUpdate}
                isDeleteDisable={isDeleteDisable}
                data={data}
                columns={columns}
                select={select}
                handleOpenCreate={handleOpenCreate}
                handleOpenDelete={handleOpenDelete}
                handleOpenReference={handleOpenReference}
                handleOpenLock={handleOpenLock}
                isLockDisable={isLockDisable}
                handleChangeFilter={handleChangeFilter}
                filter={exportFilter}
            />

            {isLoading
                ? <div className={"flex h-full w-full justify-center items-center"}>
                    <MainLoader/>
                </div>
                : data.length === 0
                    ? <div className={'m-auto'}>
                        <NoData askLoad={askLoad}/>
                    </div>
                    : <div className={'flex flex-col'} style={{ width: "calc(100% - 50px)" }}>
                        <div className={'w-full flex overflow-auto z-[1]'} ref={divRef} onScroll={handleTableScroll}>
                            <table className={'w-full'}>
                                <RenderHeaderTable
                                    key={`columns-FixedSizeList-Key`}
                                    rows={rows}
                                    columnsRendered={columnsRendered}
                                    setSelect={setSelect}
                                    select={select}
                                    columns={columns}
                                    handleSort={handleSort}
                                    sort={sort}
                                    onSelect={onSelect}
                                    thRef={thRef}
                                />

                                <tbody ref={tbodyRef}>
                                    {renderedRows.map((elem: any, index: number) => {
                                        const row = elem;

                                        return (
                                            <RenderRow
                                                scrollColumnName={scrollColumnName}
                                                key={`${index}-columns-FixedSizeList-Key`}
                                                index={index}
                                                row={row}
                                                select={select}
                                                columnsRendered={columnsRendered}
                                                handleChangeChecked={handleChangeChecked}
                                                isResultOther={isResultOther}
                                                tableCellStyleUpdateOnScroll={tableCellStyleUpdateOnScroll}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        {askLoad
                            ? <div style={{padding: 40}} className={"font-bold px-20"}>
                                Veuillez sélectionner au moins un périmètre et cliquer sur "Afficher"
                            </div>
                            : null
                        }

                        <div className={'flex bg-white mt-auto justify-between z-[1]'}>
                            <div className={'p-[12px]'}>
                                {select.length > 1
                                    ? `${select.length} lignes sélectionnées`
                                    : select.length === 1
                                        ? `${select.length} ligne sélectionnée`
                                        : "Aucune ligne sélectionnée"
                                }
                            </div>

                            <TablePagination
                                labelDisplayedRows={({from, to, count}: {from: number, to: number, count: number}): string => `${from}-${to} sur ${count}`}
                                component="div"
                                count={!!pagination && !!pagination.total ? pagination?.total : rows.length}
                                rowsPerPage={!!pagination && !!pagination.par_page ? pagination?.par_page : 50}
                                rowsPerPageOptions={[!!pagination && !!pagination.par_page ? pagination?.par_page : 50]}
                                page={page}
                                onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number): void => {
                                    setPage(newPage);

                                    if (!loadedPage.includes(newPage)) {
                                        if (handlePageChange) handlePageChange(newPage + 1, filter, false, sort);

                                        setLoadedPage((prevLoadedPage: number[]) => [...prevLoadedPage, newPage]);
                                    }

                                    if (divRef.current) divRef.current.scrollTop = 0;
                                }}
                            />
                        </div>
                    </div>
            }
        </div>
    );
}